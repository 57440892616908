.zaid-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 9999;
  padding: 200px 25px 25px;
}

.zaid-form-popup {
  background-color: rgba(26, 26, 26, 0.4);
}

.zaid-popup-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 540px;
  min-height: 292px;
  background: #90daff;
  border: 1px solid #fff;
  z-index: 3;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.zaid-popup__body {
  width: 100%;
  max-width: 540px;
  min-height: 282px;
  position: relative;
  background: #90daff;
  border: 1px solid #fff;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.zaid-form-popup__body {
  max-width: 540px;
  min-height: 282px;
  position: relative;
  background: #90daff;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
}

.zaid-form-popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.zaid-popup__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.zaid-popup__header {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.zaid-form-popup__header {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  text-transform: uppercase;
}

.zaid-popup__text {
  font-size: 20px;
  line-height: 20px;
}

.zaid-popup__btn {
  width: 100%;
  height: 50px;
  cursor: pointer;
  background: #22292f;
  color: white;
  border: none;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #fff;
  text-transform: uppercase;
}

.zaid-popup__btn-container {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
}

.zaid-popup__btn-cookie {
  width: 100%;
  height: 40px;
  cursor: pointer;
  background: #22292f;
  border: none;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.zaid-popup__btn-agree {
  background-color: #fff;
  color: #90daff;
  max-width: 201px;
}

.disabled {
  display: none;
}

@media (max-width: 480px) {
  .zaid-popup__btn-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .zaid-popup__btn-agree {
    max-width: 100%;
  }
}
