.zaid-about {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 20px;
  justify-content: center;
  max-width: 1030px;

}

@media (max-width: 749px) {
  .zaid-about__body {
    gap: 20px;
  }
}
