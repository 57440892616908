@import url("/src/vendor/fonts/fonts.css");
@import url("/src/vendor/normalize.css");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.zaid-page {
  max-width: 1440px;
  margin: 0 auto;
  text-align: left;
  font-family: "Manrope", "Arial", sans-serif;
  background-color: #90daff;
}

h1,
h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  font-family: "Raleway", sans-serif;
  color: #fff;
  text-transform: uppercase;
}

p,
a,
li {
  margin: 0;
  color: #fff;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.t-24 {
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;
  color: #90daff;
}

.t-20 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.t-18 {
  font-size: 18px;
  line-height: 21px;
}

.t-16 {
  font-size: 16px;
  line-height: 19px;
}

.text-center {
  text-align: center;
}

.fw-500 {
  font-weight: 500;
}

.pad {
  max-width: 1280px;
  margin: 100px auto 0;
  padding: 0 20px;
}

.zaid-privacy h1,
.zaid-privacy h2 {
  font-size: 31px;
  line-height: 40px;
  text-align: start;
}

.zaid-privacy {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

@media (max-width: 1100px) {
  h1,
  h2 {
    font-size: 30px;
    line-height: 30px;
  }

  .pad {
    margin-top: 60px;
  }
}

@media (max-width: 749px) {
  h1,
  h2 {
    font-size: 20px;
    line-height: 20px;
    text-align: start;
  }

  .pad {
    margin-top: 20px;
  }

  .t-20,
  .t-24,
  .t-18 {
    font-size: 16px;
    line-height: 19px;
  }

  .t-16 {
    font-size: 14px;
    line-height: 17px;
  }

  .zaid-privacy h1,
  .zaid-privacy h2 {
    font-size: 21px;
    line-height: 30px;
    text-align: start;
    margin-top: 10px;
  }

  .zaid-privacy__body {
    gap: 10px;
  }

  .zaid-privacy {
    padding: 10px 20px;
  }
}

@media (max-width: 350px) {
  .zaid-privacy h1,
  .zaid-privacy h2 {
    word-break: break-all;
  }
}
