.zaid-faq__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.zaid-faq__item {
  background-color: #22292F;
  min-height: 137px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}


@media (max-width: 749px) {
  .zaid-faq {
    margin: 20px auto 20px;
  }
}
