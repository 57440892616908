.zaid-main__cover {
  min-height: 500px;
  display: flex;
  align-items: center;
  padding: 20px 100px;
  background: url('/src/images/main-cover.webp') center / cover no-repeat;
}

.zaid-main__header {
  font-size: 60px;
  line-height: 60px;
  max-width: 723px;
  text-align: start;
}


@media (max-width: 1100px) {
  .zaid-main__cover {
    padding: 20px;
  }

  .zaid-main__header {
    font-size: 49px;
    line-height: 49px;
    max-width: 420px;
  }
}

@media (max-width: 749px) {
  .zaid-main__cover {
    min-height: 400px;
    background: url('/src/images/main-cover-mob.webp') center / cover no-repeat;
  }

  .zaid-main__header {
    font-size: 32px;
    line-height: 32px;
    max-width: 207px;
    word-break: break-all;
  }
}
