.zaid-game-page {
  background-color: #22292f;
  margin-top: 40px;
}

.zaid-game-page__body {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
}

.zaid-game-page__top {
  display: flex;
  gap: 20px;
}

.zaid-game-page__text {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}

.zaid-game-page__text-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
  max-width: 505px;
}

.zaid-game-page__header {
  font-weight: 600;
  text-align: start;
}

.zaid-game-page__stars-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.zaid-game-page__rating {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}

.zaid-game-page__qr-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  align-self: end;
}

.zaid-game-page__images {
  width: 100%;
  height: auto;
}

.zaid-game-page__qr-text {
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

@media (max-width: 1100px) {
  .zaid-game-page__top {
    flex-direction: column;
    padding-top: 60px;
    position: relative;
  }

  .zaid-game-page__header {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }

  .zaid-game-page__text-left {
    max-width: 100%;
  }
}

@media (max-width: 749px) {
  .zaid-game-page__header {
    font-size: 36px;
    line-height: 43px;
  }

  .zaid-game-page__text {
    flex-direction: column;
  }

  .zaid-game-page__qr-container {
    align-self: start;
    width: 100%;
  }

  .zaid-game-page__img {
    width: 100%;
    height: auto;
    max-width: 400px;
  }

  .zaid-game-page__stars-img {
    width: 100%;
    height: auto;
    max-width: 214px;
  }

  .zaid-game-page__stars-container {
    flex-wrap: wrap;
  }

  .zaid-game-page__rating {
    font-size: 36px;
    line-height: 54px;
  }

  .zaid-game-page__bottom {
    display: none;
  }

  .zaid-game-page__qr-container img {
    width: 100%;
    height: auto;
    max-width: 319px;
  }

  .zaid-game-page__top,
  .zaid-game-page__text-left {
    align-items: center;
  }
}
