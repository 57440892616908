.zaid-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.zaid-form__text {
  max-width: 610px;
}

.zaid-form__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 610px;
}

.zaid-form__input {
  height: 47px;
  border-radius: 6px;
  padding-left: 10px;
  border: none;
}

.zaid-form__input::placeholder {
  text-transform: uppercase;
  color: #90daff;
}

.zaid-form__btn {
  background-color: #22292f;
  border: 1px solid #fff;
  height: 47px;
  color: #fff;
  border-radius: 6px;
  margin-top: 20px;
}
