.zaid-reviews__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.zaid-reviews__item {
  background-color: #22292f;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.zaid-reviews__text {
  margin-top: 20px;
}

@media (max-width: 749px) {
  .zaid-reviews__list {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 30px auto 0;
  }

  .zaid-reviews__img {
    width: 100px;
    height: 100px;
  }
}