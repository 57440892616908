.zaid-header__container {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  margin: 0 auto;
  gap: 20px;
  border-bottom: 1px solid #ffffff;
}

.zaid-header__link-container {
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
  z-index: 3;
}

.zaid-header__link {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  transition: all 0.3s;
  text-transform: uppercase;
}
.zaid-header__link:hover {
  color: #22292f;
}

.burger-menu {
  display: none;
  cursor: pointer;
}

.burger__close-btn {
  display: none;
}

.zaid-burger__icon {
  width: 30px;
}

.burger-menu__nav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: white;
  transition: left 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.burger-menu__nav.active {
  left: 0;
}

.burger-menu__nav-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}

.burger-menu__nav-link {
  margin: 10px 0 20px;
}

.burger__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  cursor: pointer;
}

.burger-menu__nav.active .burger__close-btn {
  display: block;
}

.burger-menu.active #burger-icon {
  display: none;
}

@media (max-width: 1100px) {
  .zaid-header__container {
    padding: 0 20px;
  }
}

@media (max-width: 749px) {
  .burger-menu {
    display: flex;
  }

  .zaid-header__link-container {
    display: none;
  }

  .zaid-header__link {
    color: #90daff;
    font-weight: 600;
  }
}
