.zaid-games__swiper {
  margin-top: -42px;
  padding-top: 75px;
  padding-bottom: 35px;
}

.zaid-games__slide {
  background-color: #22292f;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.zaid-games__link {
  background-color: #90daff;
  border: 1px solid #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  text-transform: uppercase;
  transition: all 0.3s;
}

.zaid-games__link:hover {
  background-color: #22292f;
  color: #90daff;
  border: 1px solid #90daff;
}

.zaid-games__img {
  width: 100%;
  height: auto;
}

.swiper-button-next {
  background: url("../../images/next.svg") center / contain no-repeat;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.swiper-button-prev {
  background: url("../../images/prev.svg") center / contain no-repeat;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: 65px;
  cursor: pointer;
}

.swiper-pagination-horizontal {
  align-items: center;
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background-color: #000;
}

.swiper-pagination-bullet-active {
  width: 8px;
  height: 8px;
}

@media (max-width: 749px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
  }

  .swiper-button-prev {
    right: 40px;
  }

  .zaid-games__swiper {
    margin-top: -24px;
    padding-top: 40px;
    padding-bottom: 35px;
  }

  .zaid-games__slide {
    padding: 10px;
    gap: 10px;
  }
}
