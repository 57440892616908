.zaid-footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #22292f;
  margin-top: 100px;
}

.zaid-footer__body {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin: 0 auto;
}

.zaid-footer__left {
  max-width: 408px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.zaid-footer__link {
 text-decoration: underline;
}

.zaid-footer__right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
}

.zaid-footer__social {
  display: flex;
  gap: 5px;
}

@media (max-width: 749px) {
  .zaid-footer {
    margin-top: 30px;
  }

  .zaid-footer__body {
    flex-direction: column;
    gap: 20px;
  }

  .zaid-footer__right {
    flex-direction: column-reverse;
    gap: 15px;
  }
}
